<app-guest-header [account] = "account"></app-guest-header>
<div class="container">
    <router-outlet></router-outlet>
<!--  <app-chat-big></app-chat-big>-->
<!--  <div class="w-96 mt-2">-->
<!--    <app-conversation-widget></app-conversation-widget>-->
<!--  </div>-->
</div>
<app-guest-footer></app-guest-footer>

