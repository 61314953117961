import {Component, OnDestroy, OnInit} from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { SizeOnlyDirective } from '@revolve-app/app/core/utils/size-only.directive';
import { SigninComponent } from '@revolve-app/app/theme/components/auth/signin/signin.component';
import { GuestFooterComponent } from '@revolve-app/app/theme/components/footer/guest-footer.component';
import { GuestHeaderComponent } from '@revolve-app/app/theme/components/headers/guest-header/guest-header.component';
import {
  ResponsiveWidgetComponent
} from "@revolve-app/app/theme/components/chat-components/responsive-widget/responsive-widget.component";
import {
  ConversationWidgetComponent
} from "@revolve-app/app/theme/components/chat-components/conversation-mobile-widget/conversation-widget.component";
import {StorageKeys, StorageService} from "@revolve-app/app/core/utils/storage.service";
import {getMeStartRequest} from "@revolve-app/app/core/features/accounts/store/me/me.actions";
import {Store} from "@ngrx/store";
import {MeModule} from "@revolve-app/app/core/features/accounts/store/me/me.module";
import {
  selectPlatformSettings
} from "@revolve-app/app/core/features/platform-settings/store/platform-settings/platform-settings.reducer";
import {selectMeAccount} from "@revolve-app/app/core/features/accounts/store/me/me.reducer";

@Component({
  selector: 'app-layout-guest',
  standalone: true,
  imports: [
    RouterOutlet,
    SizeOnlyDirective,
    SigninComponent,
    RouterLink,
    GuestHeaderComponent,
    GuestFooterComponent,
    ResponsiveWidgetComponent,
    ConversationWidgetComponent,
    MeModule
  ],
  templateUrl: './guest.component.html',
  styleUrl: './guest.component.scss'
})
export class GuestComponent implements OnInit, OnDestroy{
  accountSelector$: any;
  account: any;
  linkToSubscribe: any;
  constructor(private store: Store) {
    this.accountSelector$ = this.store.select(selectMeAccount)
    this.linkToSubscribe = this.accountSelector$.subscribe((data:any) => {
      this.account = data
    });
    let authToken =  StorageService.getItem(StorageKeys.TOKEN);
    if( authToken){
      this.store.dispatch(getMeStartRequest());
    }
  }


  ngOnInit(): void {
  }
  ngOnDestroy() {
    this.linkToSubscribe.unsubscribe();
  }
}
