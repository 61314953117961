import { Directive, TemplateRef, ViewContainerRef, OnDestroy, Input } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Subscription } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[sizeOnly]',
})
export class SizeOnlyDirective implements OnDestroy {
    private hasView = false;
    private subscription: Subscription | undefined;

    @Input('sizeOnly') lessThen: number = 700;
    constructor(
      private templateRef: TemplateRef<any>,
      private viewContainer: ViewContainerRef,
      private breakpointObserver: BreakpointObserver
    ) {

    }

    ngOnInit(){
      if(this.lessThen){
        this.subscription = this.breakpointObserver.observe(`(max-width: ${this.lessThen}px)`).subscribe(state => {
          if (state.matches && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
          } else if (!state.matches && this.hasView) {
            this.viewContainer.clear();
            this.hasView = false;
          }
        });
      }
    }
    ngOnDestroy(): void {
        if(this.subscription) this.subscription.unsubscribe();
    }
}
